import React from 'react';
import { Form, Input, Select } from 'antd';

import './FundRankingForm.scss';
const { Option } = Select;

const FundRankingForm = () => {

  return <div className="FundRankingForm">
    <Form.Item name="fund_ranking_classe" label="Classe" className="select" rules={[{ required: true }]}>
      <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Option value="Ações">Ações</Option>
        <Option value="Multimercado">Multimercado</Option>
        <Option value="Renda Fixa">Renda Fixa</Option>
      </Select>
    </Form.Item>

    <Form.Item
      name="fund_ranking_descricao"
      label="Descrição"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <div className="linha">
      <Form.Item
        name="fund_ranking_nome1"
        label="Nome 1"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fund_ranking_porcentagem1"
        label="Porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="linha">
      <Form.Item
        name="fund_ranking_nome2"
        label="Nome 2"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fund_ranking_porcentagem2"
        label="Porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="linha">
      <Form.Item
        name="fund_ranking_nome3"
        label="Nome 3"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fund_ranking_porcentagem3"
        label="Porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="linha">
      <Form.Item
        name="fund_ranking_nome4"
        label="Nome 4"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fund_ranking_porcentagem4"
        label="Porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="linha">
      <Form.Item
        name="fund_ranking_nome5"
        label="Nome 5"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fund_ranking_porcentagem5"
        label="Porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

  </div>
};
export default FundRankingForm;