import React, { useState } from 'react';
import { Form, Input, Radio } from 'antd';

import './TaxaJurosEUAForm.scss';

const { TextArea } = Input;

const options = [
  { label: 'Valor único', value: 'single' },
  { label: 'Faixa', value: 'range' },
];

const TaxaJurosEUAForm = () => {
  const [isFedRange, setIsFedRange] = useState(true);

  const onChangeFed = ({ target: { value } }: RadioChangeEvent) => {
    setIsFedRange(value === 'range');
  };

  return <div className="TaxaJurosEUAForm">
    <Form.Item
      name="taxa_juros_eua_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="taxa_juros_eua_texto_principal"
      label="Texto principal"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={4} />
    </Form.Item>

    <h3>Taxa-Alvo Juros EUA</h3>

    <Radio.Group options={options} onChange={onChangeFed} optionType="button" defaultValue={'range'} />
    <br />
    <br />

    <Form.Item
      name="taxa_juros_eua_valor1"
      label={isFedRange ? 'De' : 'Valor'}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    { isFedRange && (
      <Form.Item
        name="taxa_juros_eua_valor2"
        label="Até"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    )}

    <Form.Item
      name="taxa_juros_eua_texto"
      label="Texto"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={3} />
    </Form.Item>
    <Form.Item
      name="taxa_juros_eua_fonte_texto"
      label="Fonte"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


  </div>
};
export default TaxaJurosEUAForm;