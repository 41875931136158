import React from 'react';
import { Form, Input, Select } from 'antd';
import './NasdaqForm.scss';

const { Option } = Select;

const NasdaqForm = () => {

  return <div className='NasdaqForm'>
    <div className="line-full">
      <Form.Item
        name="nasdaq_date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Alta</h3>
    <div className="line-full">
      <Form.Item
        name="nasdaq_alta1_nome"
        label="Alta 1 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_alta1_variacao"
        label="Alta 1 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line-full">
      <Form.Item
        name="nasdaq_alta2_nome"
        label="Alta 2 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_alta2_variacao"
        label="Alta 2 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line-full">
      <Form.Item
        name="nasdaq_alta3_nome"
        label="Alta 3 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_alta3_variacao"
        label="Alta 3 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Baixa</h3>
    <div className="line-full">
      <Form.Item
        name="nasdaq_baixa1_nome"
        label="Baixa 1 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_baixa1_variacao"
        label="Baixa 1 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <div className="line-full">
      <Form.Item
        name="nasdaq_baixa2_nome"
        label="Baixa 2 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_baixa2_variacao"
        label="Baixa 2 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <div className="line-full">
      <Form.Item
        name="nasdaq_baixa3_nome"
        label="Baixa 3 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_baixa3_variacao"
        label="Baixa 3 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>





    <h3>Nasdaq Composite Index</h3>
    <div className="line-full">
      <Form.Item
        name="nasdaq_composite_total"
        label="Total"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_composite_variacao_valor"
        label="Variação valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nasdaq_composite_variacao_porcentagem"
        label="Variação porcentagem"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="nasdaq_composite_status" label="Status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

  </div>
};
export default NasdaqForm;