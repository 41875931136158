import React from 'react';
import { Form, Input, Select } from 'antd';

import './IronOreForm.scss';

const { Option } = Select;

const IronOreForm = () => {

  return <div className="IronOreForm">

    <div className="line">
      <Form.Item
        name="iron_ore_date"
        label="Data referência"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Fechamento</h3>
    <div className="line">
      <Form.Item name="iron_ore_fechamento_mes1" label="Mês China" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="JAN">Janeiro</Option>
          <Option value="FEV">Fevereiro</Option>
          <Option value="MAR">Março</Option>
          <Option value="ABR">Abril</Option>
          <Option value="MAI">Maio</Option>
          <Option value="JUN">Junho</Option>
          <Option value="JUL">Julho</Option>
          <Option value="AGO">Agosto</Option>
          <Option value="SET">Setembro</Option>
          <Option value="OUT">Outubro</Option>
          <Option value="NOV">Novembro</Option>
          <Option value="DEZ">Dezembro</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="iron_ore_fechamento_valor1"
        label="Valor US$ China"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_fechamento_variacao1"
        label="Variação China"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_fechamento_variacao_status1" label="Variação status China" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item name="iron_ore_fechamento_mes2" label="Mês Singapura" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="JAN">Janeiro</Option>
          <Option value="FEV">Fevereiro</Option>
          <Option value="MAR">Março</Option>
          <Option value="ABR">Abril</Option>
          <Option value="MAI">Maio</Option>
          <Option value="JUN">Junho</Option>
          <Option value="JUL">Julho</Option>
          <Option value="AGO">Agosto</Option>
          <Option value="SET">Setembro</Option>
          <Option value="OUT">Outubro</Option>
          <Option value="NOV">Novembro</Option>
          <Option value="DEZ">Dezembro</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="iron_ore_fechamento_valor2"
        label="Valor Singapura"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_fechamento_variacao2"
        label="Variação US$ Singapura"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_fechamento_variacao_status2" label="Variação status Singapura" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="iron_ore_fechamento_low"
        label="Low"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_fechamento_high"
        label="High"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_fechamento_termometro" label="Termômetro" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="extreme_fear">Extreme Fear</Option>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
          <Option value="extreme_greed">Extreme Greed</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Ao Vivo</h3>
    <div className="line">
      <Form.Item name="iron_ore_live_mes1" label="Mês China" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="JAN">Janeiro</Option>
          <Option value="FEV">Fevereiro</Option>
          <Option value="MAR">Março</Option>
          <Option value="ABR">Abril</Option>
          <Option value="MAI">Maio</Option>
          <Option value="JUN">Junho</Option>
          <Option value="JUL">Julho</Option>
          <Option value="AGO">Agosto</Option>
          <Option value="SET">Setembro</Option>
          <Option value="OUT">Outubro</Option>
          <Option value="NOV">Novembro</Option>
          <Option value="DEZ">Dezembro</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="iron_ore_live_valor1"
        label="Valor US$ China"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_live_variacao1"
        label="Variação China"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_live_variacao_status1" label="Variação status China" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item name="iron_ore_live_mes2" label="Mês Singapura" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="JAN">Janeiro</Option>
          <Option value="FEV">Fevereiro</Option>
          <Option value="MAR">Março</Option>
          <Option value="ABR">Abril</Option>
          <Option value="MAI">Maio</Option>
          <Option value="JUN">Junho</Option>
          <Option value="JUL">Julho</Option>
          <Option value="AGO">Agosto</Option>
          <Option value="SET">Setembro</Option>
          <Option value="OUT">Outubro</Option>
          <Option value="NOV">Novembro</Option>
          <Option value="DEZ">Dezembro</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="iron_ore_live_valor2"
        label="Valor US$ Singapura"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_live_variacao2"
        label="Variação Singapura"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_live_variacao_status2" label="Variação status Singapura" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="iron_ore_live_low"
        label="Low"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="iron_ore_live_high"
        label="High"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="iron_ore_live_termometro" label="Termômetro" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="extreme_fear">Extreme Fear</Option>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
          <Option value="extreme_greed">Extreme Greed</Option>
        </Select>
      </Form.Item>
    </div>

  </div>
};
export default IronOreForm;