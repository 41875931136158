import React from 'react';
import { Form, Input, Select } from 'antd';
import './BitcoinEthereumForm.scss';

const { Option } = Select;

const BitcoinEthereumForm = () => {
  return <div className="BitcoinEthereumForm">

    <div className="line">
      <Form.Item name="bitcoin_ethereum_modelo" label="Modelo" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="bitcoin">Bitcoin</Option>
          <Option value="ethereum">Ethereum</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="bitcoin_ethereum_data"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h4>Valor atual</h4>
    <div className="line">
      <Form.Item
        name="bitcoin_ethereum_atual_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_atual_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="bitcoin_ethereum_atual_status" label="Status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h4>Volume (24H)</h4>
    <div className="line">
      <Form.Item
        name="bitcoin_ethereum_volume_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_volume_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="bitcoin_ethereum_volume_status" label="Status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h4>Capitalização de mercado</h4>
    <div className="line">
      <Form.Item
        name="bitcoin_ethereum_capitalizacao_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_capitalizacao_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="bitcoin_ethereum_capitalizacao_status" label="Status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <div className="line">
      <Form.Item
        name="bitcoin_ethereum_circulacao"
        label="Quantidade em circulação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bitcoin_ethereum_emitido"
        label="Quantidade emitido"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bitcoin_ethereum_liquidez"
        label="Indicador de liquidez"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h4>Máxima de todos os tempos</h4>
    <div className="line">
      <Form.Item
        name="bitcoin_ethereum_maxima_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_maxima_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="bitcoin_ethereum_maxima_status" label="Status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="bitcoin_ethereum_maxima_data"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

  </div>
};
export default BitcoinEthereumForm;