import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form, Image, Input, InputNumber, Radio, Select, Space } from 'antd';
import moment from 'moment';
import { pagesList } from '../../constants/constants.js';
import './ImagePreview.scss';
import { imagePreviewRequests } from '../../requests';
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

const isDevMode = false;

const { Option } = Select;

const initialValues = {
  prev_tipo: 'imagettftext_espacamento',
  prev_tamanho_fonte: 26,
  prev_x: 200,
  prev_y: 540,
  prev_cor: 'gray',
  prev_fonte: 'Lato-Regular',
  prev_texto: '',
  prev_espacamento: 1,
};

const ImagePreview = ({ type, validateForm }) => {
  const [form] = Form.useForm();
  const [code, setCode] = useState('');
  const [image, setImage] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelList, setModelList] = useState([]);

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  useEffect(() => {
    const item = Object.values(pagesList).find((v) => v.slug === type);
    setModelList(item ? item.models : []);
    setSelectedModel(item?.models?.length > 0 ? item.models[0].slug : null);
    setImage(null);
  }, [type]);

  const generatePreview = async (formData) => {
    try {
      if (isDevMode) {
        await form
          .validateFields()
          .then((values) => {
            const keys = Object.keys(values);
            keys.forEach((key) => {
              if (values[key] !== undefined) {
                formData.append(key, values[key]);
                console.log(key, values[key]);
              }
            });
            console.log('formData:', formData);

            setCode(`${values.prev_tipo}($imagemSrc, ${values.prev_tamanho_fonte}, 0, ${values.prev_x}, ${values.prev_y}, ${values.prev_cor}, ${values.prev_fonte}, ${values.prev_texto}, ${values.prev_espacamento});`);

          })
          .catch((errorInfo) => {
            console.log('Erro na validação:', errorInfo);
          });
      }


      const resposta = await imagePreviewRequests.imagePreview(type, selectedModel, formData);
      URL.revokeObjectURL(image);

      // Cria um URL para o Blob
      const blobUrl = URL.createObjectURL(resposta.data);

      // Atualiza o estado com o URL do Blob
      setImage(blobUrl);
    } catch (erro) {
      setImage(null);
      console.error('Erro ao realizar a requisição:', erro);
    }
  }

  const downloadImage = async () => {
    if (!type || !selectedModel || !image) return;
    try {
      const link = document.createElement('a');
      link.href = image;
      const selectedModelString = selectedModel === 'default' ? '' : `${selectedModel}-`;
      link.setAttribute('download', `${type}-${selectedModelString}${moment().format('YYYY-MM-DD_HH-mm-ss')}`); // Substitua pelo nome desejado
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  }

//        onClick={() => validateForm(generatePreview)}

  const items = [
    {
      key: '1',
      label: 'Pré-visualizar imagens',
      children: <div className="preview-container">
        <h3>Modelo:</h3>
        <div className="models-container">
          <Radio.Group value={selectedModel} onChange={(e) => { setSelectedModel(e.target.value); }} buttonStyle="solid" style={{ marginTop: 16 }}>
            {modelList.map((v) => <Radio.Button key={v.slug} value={v.slug}>{v.name}</Radio.Button>)}
          </Radio.Group>
        </div>
        <h3>Ação:</h3>
        <div className="actions-container">
          <Button onClick={() => validateForm(generatePreview)}>Visualizar</Button>
          <Button onClick={downloadImage} disabled={!image}>Baixar</Button>
        </div>
        <div style={{ display: 'flex' }}>
          {!!isDevMode && (
            <div className="dev-img-container">
              <Form
                form={form}
                name="basic"
                layout="vertical"
                initialValues={initialValues}
                autoComplete="off"
              >

                <Form.Item name="prev_tipo" label="Tipo" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Select>
                    <Option value="imagettftext_espacamento">imagettftext_espacamento</Option>
                    <Option value="imagettftext_espacamento_direita">imagettftext_espacamento_direita</Option>
                    <Option value="imagettftext_espacamento_max">imagettftext_espacamento_max</Option>
                    <Option value="textoComQuebraDeLinha">textoComQuebraDeLinha</Option>
                    <Option value="textoCentralizadoComQuebraDeLinha">textoCentralizadoComQuebraDeLinha</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="prev_tamanho_fonte" label="Tamanho fonte" className="select" rules={[{ required: true }]}>
                  <InputNumber rules={[{ required: true, message: 'Campo obrigatório!' }]} />
                </Form.Item>

                <Form.Item name="prev_x" label="X" className="select" rules={[{ required: true }]}>
                  <InputNumber rules={[{ required: true, message: 'Campo obrigatório!' }]} />
                </Form.Item>

                <Form.Item name="prev_y" label="Y" className="select" rules={[{ required: true }]}>
                  <InputNumber rules={[{ required: true, message: 'Campo obrigatório!' }]} />
                </Form.Item>

                <Form.Item name="prev_cor" label="Cor" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Select>
                    <Option value="gray">Cinza</Option>
                    <Option value="black">Preto</Option>
                    <Option value="white">Branco</Option>
                    <Option value="green">Verde</Option>
                    <Option value="red">Vermelho</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="prev_fonte" label="Fonte tipo" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Select>
                    <Option value="Klavika-Bold">Klavika-Bold</Option>
                    <Option value="Klavika-BoldItalic">Klavika-BoldItalic</Option>
                    <Option value="Klavika-Light">Klavika-Light</Option>
                    <Option value="Klavika-LightItalic">Klavika-LightItalic</Option>
                    <Option value="Klavika-Medium">Klavika-Medium</Option>
                    <Option value="Klavika-MediumItalic">Klavika-MediumItalic</Option>
                    <Option value="Klavika-RegularItalic">Klavika-RegularItalic</Option>

                    <Option value="Lato-Bold">Lato-Bold</Option>
                    <Option value="Lato-SemiBold">Lato-SemiBold</Option>
                    <Option value="Lato-Light">Lato-Light</Option>
                    <Option value="Lato-Regular">Lato-Regular</Option>

                    <Option value="Sora-Bold">Sora-Bold</Option>
                    <Option value="Sora-ExtraLight">Sora-ExtraLight</Option>
                    <Option value="Sora-Light">Sora-Light</Option>
                    <Option value="Sora-Regular">Sora-Regular</Option>
                    <Option value="Sora-SemiBold">Sora-SemiBold</Option>
                    <Option value="Sora-Thin">Sora-Thin</Option>

                  </Select>
                </Form.Item>

                <Form.Item name="prev_texto" label="Texto" className="select">
                  <Input rules={[{ required: true, message: 'Campo obrigatório!' }]} />
                </Form.Item>

                <Form.Item name="prev_espacamento" label="Fonte tamanho" className="select" rules={[{ required: true }]}>
                  <InputNumber rules={[{ required: true, message: 'Campo obrigatório!' }]} step="0.1" />
                </Form.Item>

                <div className="code-container">
                  {code}
                  <br />
                  <br />
                </div>

              </Form>




            </div>
          )}
          <div className="img-container" onClick={() => isDevMode ? validateForm(generatePreview) : false}>
            {image &&
              <Image
                src={image}
                alt="Pré-visualização"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={50} className="toolbar-wrapper">
                      <DownloadOutlined onClick={downloadImage} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            }
          </div>
        </div>
      </div>,
    },
  ];

  return (
    <div className="ImagePreview">
      <Collapse items={items} defaultActiveKey={isDevMode ? ['1'] : []} onChange={onChange} />
    </div>
  );
};

export default ImagePreview;
