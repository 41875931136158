import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivatePageWrapper from '../pages/privatePageWrapper/PrivatePageWrapper';
import PackageGenerator from '../components/packageGenerator/PackageGenerator';
import Home from '../pages/home/Home';
import Login from '../pages/accessPages/login/Login';
import ListarAssessores from '../pages/assessores/ListarAssessores';
import GerenciarAssessores from '../pages/assessores/GerenciarAssessores';
import { pagesList } from '../constants/constants.js';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<PrivateRoute/>}>
          <Route exact path='/' element={<Home />}/>
          {Object.values(pagesList).map((v) => (
            <Route key={v.slug} path={`/${v.slug}`} element={<PrivatePageWrapper slug={v.slug} pageTitle={v.pageTitle} menuParent={v.menuParent}>
              <PackageGenerator type={v.slug} />
            </PrivatePageWrapper>} />
          ))}
          <Route path='/assessores/novo'  element={<PrivatePageWrapper slug="assessores" pageTitle="Assessores" menuParent="Assessores">
            <GerenciarAssessores />
          </PrivatePageWrapper>} />
          <Route path='/assessores/listar'  element={<PrivatePageWrapper slug="assessores" pageTitle="Assessores" menuParent="Assessores">
            <ListarAssessores />
          </PrivatePageWrapper>} />
        </Route>
        <Route exact path='/login' element={<Login/>}/>
        <Route exact path='*' element={<Home />}/>
      </Routes>
    </Router>
  );
};

export default AppRouter;
