import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { selectStringFilter } from '../../../utils/forms';

import './TemporadaBalancosForm.scss';

const { Option } = Select;

const companyList = [
  { value: 'RRRP3', label: "3R PETROLEUM (RRRP3)" },
  { value: 'ALOS3', label: "ALLOS (ALOS3)" },
  { value: 'ALPA3', label: "ALPAGARTAS (ALPA3)" },
  { value: 'ALPA4', label: "ALPAGARTAS (ALPA4)" },
  { value: 'ABEV3', label: "AMBEV (ABEV3)" },
  { value: 'ARZZ3', label: "AREZZO & CO (ARZZ3)" },
  { value: 'ASAI3', label: "ASSAI (ASAI3)" },
  { value: 'AURE3', label: "AUREN (AURE3)" },
  { value: 'AZUL4', label: "AZUL (AZUL4)" },
  { value: 'B3SA3', label: "B3 (B3SA3)" },
  { value: 'BBAS3', label: "BANCO DO BRASIL (BBAS3)" },
  { value: 'BBSE3', label: "BB SEGURIDADE (BBSE3)" },
  { value: 'BRBI11', label: "BR PARTNERS (BRBI11)" },
  { value: 'BBDC3', label: "BRADESCO (BBDC3)" },
  { value: 'BBDC4', label: "BRADESCO (BBDC4)" },
  { value: 'BRAP4', label: "BRADESPAR (BRAP4)" },
  { value: 'BRKM5', label: "BRASKEM (BRKM5)" },
  { value: 'BRFS3', label: "BRF SA (BRFS3)" },
  { value: 'BPAC11', label: "BTGP BANCO (BPAC11)" },
  { value: 'CAML3', label: "CAMIL (CAML3)" },
  { value: 'CRFB3', label: "CARREFUOR BR (CRFB3)" },
  { value: 'BHIA3', label: "CASAS BAHIA (BHIA3)" },
  { value: 'CCRO3', label: "CCR SA (CCRO3)" },
  { value: 'CMIG4', label: "CEMIG (CMIG4)" },
  { value: 'CIEL3', label: "CIELO (CIEL3)" },
  { value: 'COGN3', label: "COGNA ON (COGN3)" },
  { value: 'CPLE6', label: "COPEL (CPLE6)" },
  { value: 'CSAN3', label: "COSAN (CSAN3)" },
  { value: 'CPFE3', label: "CPFL ENERGIA (CPFE3)" },
  { value: 'CMIN3', label: "CSN MINERAÇÃO (CMIN3)" },
  { value: 'CVCB3', label: "CVC BRASIL (CVCB3)" },
  { value: 'CYRE3', label: "CYRELA REALT (CYRE3)" },
  { value: 'DXCO3', label: "DEXCO (DXCO3)" },
  { value: 'ELET3', label: "ELETROBRAS (ELET3)" },
  { value: 'ELET6', label: "ELETROBRAS (ELET6)" },
  { value: 'EMBR3', label: "EMBRAER (EMBR3)" },
  { value: 'ENGI11', label: "ENERGISA (ENGI11)" },
  { value: 'ENEV3', label: "ENEVA (ENEV3)" },
  { value: 'EGIE3', label: "ENGIE (EGIE3)" },
  { value: 'EQTL3', label: "EQUATORIAL (EQTL3)" },
  { value: 'EZTC3', label: "EZTEC (EZTC3)" },
  { value: 'FLRY3', label: "FLEURY (FLRY3)" },
  { value: 'GGBR4', label: "GERDAU (GGBR4)" },
  { value: 'GOAU4', label: "GERDAU (GOAU4)" },
  { value: 'PCAR3', label: "GPA (PCAR3)" },
  { value: 'NTCO3', label: "GRUPO NATURA (NTCO3)" },
  { value: 'SOMA3', label: "GRUPO SOMA (SOMA3)" },
  { value: 'HAPV3', label: "HAPVIDA (HAPV3)" },
  { value: 'HYPE3', label: "HYPERA (HYPE3)" },
  { value: 'IGTI', label: "IGUATEMI S.A. (IGTI)" },
  { value: 'IRBR3', label: "IRBBRASIL RE (IRBR3)" },
  { value: 'ITSA4', label: "ITAUSA (ITSA4)" },
  { value: 'ITUB4', label: "ITAÚ UNIBANCO (ITUB4)" },
  { value: 'JBSS3', label: "JBS (JBSS3)" },
  { value: 'KLBN11', label: "KLABIN (KLBN11)" },
  { value: 'RENT3', label: "LOCALIZA (RENT3)" },
  { value: 'LOGG3', label: "LOG COMMERCIAL PROPERTIES (LOGG3)" },
  { value: 'LREN3', label: "LOJAS RENNER (LREN3)" },
  { value: 'LWSA3', label: "LWSA (LWSA3)" },
  { value: 'MGLU3', label: "MAGAZINE LUIZA (MGLU3)" },
  { value: 'MRFG3', label: "MARFRIG (MRFG3)" },
  { value: 'BEEF3', label: "MINERVA (BEEF3)" },
  { value: 'MRVE3', label: "MRV (MRVE3)" },
  { value: 'MULT3', label: "MULTIPLAN (MULT3)" },
  { value: 'PETR3', label: "PETROBRAS (PETR3)" },
  { value: 'PETR4', label: "PETROBRAS (PETR4)" },
  { value: 'RECV3', label: "PETRORECSA (RECV3)" },
  { value: 'PRIO3', label: "PETRORIO (PRIO3)" },
  { value: 'PETZ3', label: "PETZ (PETZ3)" },
  { value: 'RADL3', label: "RAIADROGASIL (RADL3)" },
  { value: 'RAIZ4', label: "RAIZEN (RAIZ4)" },
  { value: 'RDOR3', label: "REDE D'OR (RDOR3)" },
  { value: 'RAIL3', label: "RUMO S.A. (RAIL3)" },
  { value: 'SBSP3', label: "SABESP (SBSP3)" },
  { value: 'SANB11', label: "SANTANDER BR (SANB11)" },
  { value: 'CSNA3', label: "SID NACIONAL (CSNA3)" },
  { value: 'SLCE3', label: "SLC AGRICOLA (SLCE3)" },
  { value: 'SUZB3', label: "SUZANO S.A. (SUZB3)" },
  { value: 'SMTO3', label: "SÃO MARTINHO (SMTO3)" },
  { value: 'TAEE11', label: "TAESA (TAEE11)" },
  { value: 'VIVT3', label: "TELEFÔNICA BRASIL (VIVT3)" },
  { value: 'TIMS3', label: "TIM (TIMS3)" },
  { value: 'TOTS3', label: "TOTVS (TOTS3)" },
  { value: 'TRPL4', label: "TRAN PAULIST (TRPL4)" },
  { value: 'UGPA3', label: "ULTRAPAR (UGPA3)" },
  { value: 'USIM5', label: "USIMINAS (USIM5)" },
  { value: 'VALE3', label: "VALE (VALE3)" },
  { value: 'VAMO3', label: "VAMOS (VAMO3)" },
  { value: 'VBBR3', label: "VIBRA (VBBR3)" },
  { value: 'WEGE3', label: "WEG (WEGE3)" },
  { value: 'YDUQ3', label: "YDUQS PART (YDUQ3)" },
];

const TemporadaBalancosForm = () => {

  return <div className="TemporadaBalancosForm">

    <Form.Item
      name="temporada_balancos_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>Balanços</h3>
    <Form.List name="temporada_balancos">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'ticker']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120 }}
              >
                <Select
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  placeholder="Companhia"
                  filterOption={selectStringFilter}
                  allowClear
                  showSearch
                  options={companyList}
                />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'horario']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120 }}
              >
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Horário">
                  <Option value="antes_abertura">Antes da abertura</Option>
                  <Option value="apos_fechamento">Após o fechamento</Option>
                  <Option value="horario_confirmar">Horário a confirmar</Option>
                </Select>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default TemporadaBalancosForm;