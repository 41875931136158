import React, { useState } from 'react';
import { Form, Input, Radio } from 'antd';

import './SuperQuartaForm.scss';

const { TextArea } = Input;

const options = [
  { label: 'Valor único', value: 'single' },
  { label: 'Faixa', value: 'range' },
];

const SuperQuartaForm = () => {
  const [isFedRange, setIsFedRange] = useState(true);
  const [isCopomRange, setIsCopomRange] = useState(false);

  const onChangeFed = ({ target: { value } }: RadioChangeEvent) => {
    setIsFedRange(value === 'range');
  };

  const onChangeCopom = ({ target: { value } }: RadioChangeEvent) => {
    setIsCopomRange(value === 'range');
  };

  return <div className="SuperQuartaForm">
    <Form.Item
      name="super_quarta_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="super_quarta_texto"
      label="Texto principal"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={4} />
    </Form.Item>

    <h3>FED</h3>

    <Radio.Group options={options} onChange={onChangeFed} optionType="button" defaultValue={'range'} />
    <br />
    <br />

    <Form.Item
      name="super_quarta_fed_valor1"
      label={isFedRange ? 'De' : 'Valor'}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    { isFedRange && (
      <Form.Item
        name="super_quarta_fed_valor2"
        label="Até"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    )}

    <Form.Item
      name="super_quarta_fed_texto"
      label="Texto"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={3} />
    </Form.Item>
    <Form.Item
      name="super_quarta_fed_fonte_texto"
      label="Fonte"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <h3>COPOM</h3>

    <Radio.Group options={options} onChange={onChangeCopom} optionType="button" defaultValue={'single'} />
    <br />
    <br />

    <Form.Item
      name="super_quarta_copom_valor1"
      label={isCopomRange ? 'De' : 'Valor'}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    { isCopomRange && (
      <Form.Item
        name="super_quarta_copom_valor2"
        label="Até"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    )}

    <Form.Item
      name="super_quarta_copom_texto"
      label="Texto"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={3} />
    </Form.Item>
    <Form.Item
      name="super_quarta_copom_fonte_texto"
      label="Fonte"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

  </div>
};
export default SuperQuartaForm;