import React from 'react';
import { Button, Form, Input, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './BreakingNewsForm.scss';
import { dummyRequest, normFile } from '../../../../utils/upload';

const { TextArea } = Input;
const { Option } = Select;

const BreakingNewsForm = () => {

  return <>
    <div>

      <Form.Item name="noticia_urgente_cor_linha" label="Cor da linha" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="azul">Azul</Option>
          <Option value="verde">Verde</Option>
          <Option value="vermelho">Vermelho</Option>
        </Select>
      </Form.Item>
    </div>

    <div>
      <Form.Item
        name="noticia_urgente_titulo"
        label="Título"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item name="noticia_urgente_tamanho_fonte_titulo" label="Tamanho fonte título" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          {Array(61).fill(0).map((v, i) => (
            <Option key={i + 40} value={i + 40}>{i + 40}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div>
      <Form.Item
        name="noticia_urgente_texto"
        label="Texto"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <TextArea />
      </Form.Item>
    </div>
    <div>
      <Form.Item
        name="noticia_urgente_fonte_noticia"
        label="Fonte"
      >
        <Input />
      </Form.Item>
    </div>

    <div>

      <Form.Item
        name="noticia_urgente_imagem"
        label="Imagem"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="noticia_urgente_imagem"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>




    </div>
  </>
};
export default BreakingNewsForm;
