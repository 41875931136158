import MarketClosureForm from '../components/forms/marketClosureForm/MarketClosureForm.js';
import NasdaqForm from '../components/forms/nasdaqForm/NasdaqForm.js';
import AmericanIndexesForm from '../components/forms/americanIndexesForm/AmericanIndexesForm.js';
import AgendaDiaForm from '../components/forms/agendaEconomica/agendaDiaForm/AgendaDiaForm.js';
import TesouroDiretoForm from '../components/forms/tesouroDiretoForm/TesouroDiretoForm.js';
import TesouroDiretoFeedForm from '../components/forms/tesouroDiretoFeedForm/TesouroDiretoFeedForm.js';
import FluxoInvestimentoB3Form from '../components/forms/fluxoInvestimentoB3Form/FluxoInvestimentoB3Form.js';
import FundosImobiliariosForm from '../components/forms/fundosImobiliariosForm/FundosImobiliariosForm.js';
import AgendaSemanaForm from '../components/forms/agendaEconomica/agendaSemanaForm/AgendaSemanaForm.js';
import AgriculturalCommoditiesForm from '../components/forms/agriculturalCommoditiesForm/AgriculturalCommoditiesForm.js';
import JurosFuturosForm from '../components/forms/jurosFuturosForm/JurosFuturosForm.js';
import IpcaForm from '../components/forms/ipcaForm/IpcaForm.js';
import MercadosGlobaisForm from '../components/forms/mercadosGlobaisForm/MercadosGlobaisForm.js';
import MercadoCryptoForm from '../components/forms/mercadoCryptoForm/MercadoCryptoForm.js';
import BalancaComercialForm from '../components/forms/balancaComercialForm/BalancaComercialForm.js';
import BitcoinEthereumForm from '../components/forms/bitcoinEthereumForm/BitcoinEthereumForm.js';
import IronOreForm from '../components/forms/ironOreForm/IronOreForm.js';
import PetroleoForm from '../components/forms/petroleoForm/PetroleoForm.js';
import MinuteForm from '../components/forms/minuteForm/MinuteForm.js';
import FocusForm from '../components/forms/focusForm/FocusForm.js';
import JornalForm from '../components/forms/jornalForm/JornalForm.js';
import FormulaDrawingForm from '../components/forms/formulaDrawingForm/FormulaDrawingForm.js';
import TemporadaBalancosForm from '../components/forms/temporadaBalancosForm/TemporadaBalancosForm.js';
import SuperQuartaForm from '../components/forms/fedCopom/superQuartaForm/SuperQuartaForm.js';
import TaxaJurosEUAForm from '../components/forms/taxaJurosEUAForm/TaxaJurosEUAForm.js';
import MetalCommoditiesForm from '../components/forms/metalCommoditiesForm/MetalCommoditiesForm.js';
import MoedasMundoForm from '../components/forms/moedasMundoForm/MoedasMundoForm.js';
import TaxaSelicForm from '../components/forms/fedCopom/taxaSelicForm/TaxaSelicForm.js';
import NoticiasCryptoForm from '../components/forms/noticiasCryptoForm/NoticiasCryptoForm.js';
import TwitterForm from '../components/forms/twitterForm/TwitterForm.js';
import ImagemPersonalizadaForm from '../components/forms/imagemPersonalizadaForm/ImagemPersonalizadaForm.js';
import FundRankingForm from '../components/forms/fundRankingForm/FundRankingForm.js';
import FeedDropForm from '../components/forms/feed/feedDropForm/FeedDropForm.js';
import FeedRiseForm from '../components/forms/feed/feedRiseForm/FeedRiseForm.js';
import FeedCurrencyForm from '../components/forms/feed/feedCurrencyForm/FeedCurrencyForm.js';
import HappeningNewsBrazilForm from '../components/forms/news/happeningNewsBrazilForm/HappeningNewsBrazilForm.js';
import HappeningNewsWorldForm from '../components/forms/news/happeningNewsWorldForm/HappeningNewsWorldForm.js';
import BreakingNewsForm from '../components/forms/news/breakingNewsForm/BreakingNewsForm.js';
import AberturaMercadoMontebravoForm from '../components/forms/montebravo/aberturaMercadoMontebravoForm/AberturaMercadoMontebravoForm.js';
import FechamentoMercadoMontebravoForm from '../components/forms/montebravo/fechamentoMercadoMontebravoForm/FechamentoMercadoMontebravoForm.js';

export const serverNamePhp = process.env.REACT_APP_SERVER_NAME_PHP;
export const serverNameJava = process.env.REACT_APP_SERVER_NAME_JAVA;

export const pagesList = {
  agendaDia: {
    slug: 'agenda-dia',
    menuName: 'Agenda Dia',
    menuParent: 'agenda_economica',
    pageTitle: 'Agenda Econômica - Dia',
    component: <AgendaDiaForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  agendaSemana: {
    slug: 'agenda-semana',
    menuName: 'Agenda Semana',
    menuParent: 'agenda_economica',
    pageTitle: 'Agenda Econômica - Semana',
    component: <AgendaSemanaForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  agriculturalCommodities: {
    slug: 'commodities-agricolas',
    menuName: 'Commodities Agrícolas',
    menuParent: null,
    pageTitle: 'Commodities Agrícolas',
    component: <AgriculturalCommoditiesForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'GWM' },
      { slug: 'the-hill', name: 'The Hill' },
    ],
  },
  jurosFuturos: {
    slug: 'juros-futuros',
    menuName: 'Juros Futuros',
    menuParent: null,
    pageTitle: 'Juros Futuros',
    component: <JurosFuturosForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed', name: 'Feed' },
    ],
  },
  ipca: {
    slug: 'ipca',
    menuName: 'IPCA',
    menuParent: null,
    pageTitle: 'IPCA',
    component: <IpcaForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed1', name: 'Feed 1' },
      { slug: 'feed2', name: 'Feed 2' },
    ],
  },
  mercadosGlobais: {
    slug: 'mercados-globais',
    menuName: 'Mercados Globais',
    menuParent: null,
    pageTitle: 'Mercados Globais',
    component: <MercadosGlobaisForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  bitcoinEthereum: {
    slug: 'bitcoin-ethereum',
    menuName: 'Bitcoin/Ethereum',
    menuParent: null,
    pageTitle: 'Bitcoin/Ethereum',
    component: <BitcoinEthereumForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  ironOre: {
    slug: 'minerio-de-ferro',
    menuName: 'Minério de Ferro',
    menuParent: null,
    pageTitle: 'Minério de Ferro',
    component: <IronOreForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  petroleo: {
    slug: 'petroleo',
    menuName: 'Petróleo',
    menuParent: null,
    pageTitle: 'Petróleo',
    component: <PetroleoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  marketClosure: {
    slug: 'fechamento-mercado',
    menuName: 'Fechamento de Mercado',
    menuParent: null,
    pageTitle: 'Fechamento Mercado',
    component: <MarketClosureForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'GWM' },
      { slug: 'the-hill', name: 'The Hill' },
      { slug: 'MontagCapital', name: 'Montag Capital' },
      { slug: 'MontebravoAltas', name: 'Montebravo Altas' },
      { slug: 'MontebravoBaixas', name: 'Montebravo Baixas' },
    ],
  },
  mercadoCrypto: {
    slug: 'mercado-crypto',
    menuName: 'Mercado Crypto',
    menuParent: null,
    pageTitle: 'Mercado Crypto',
    component: <MercadoCryptoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  balancaComercial: {
    slug: 'balanca-comercial',
    menuName: 'Balança Comercial',
    menuParent: null,
    pageTitle: 'Balança Comercial',
    component: <BalancaComercialForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  nasdaq: {
    slug: 'nasdaq',
    menuName: 'Nasdaq',
    menuParent: null,
    pageTitle: 'Nasdaq',
    component: <NasdaqForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  americanIndexes: {
    slug: 'indices-americanos',
    menuName: 'Índices Americanos',
    menuParent: null,
    pageTitle: 'Índices Americanos',
    component: <AmericanIndexesForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed1', name: 'Feed 1' },
      { slug: 'feed2', name: 'Feed 2' },
    ],
  },
  feedRise: {
    slug: 'feed-alta',
    menuName: 'Maiores altas',
    menuParent: 'feed_semanal',
    pageTitle: 'Feed Semanal - Maiores altas',
    component: <FeedRiseForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'Padrão' },
      { slug: 'gwm-azul', name: 'Azul' },
    ],
  },
  feedDrop: {
    slug: 'feed-baixa',
    menuName: 'Maiores baixas',
    menuParent: 'feed_semanal',
    pageTitle: 'Feed Semanal - Maiores baixas',
    component: <FeedDropForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'Padrão' },
      { slug: 'gwm-azul', name: 'Azul' },
    ],
  },
  feedCurrency: {
    slug: 'feed-moedas',
    menuName: 'Ibov/Bitcoin/Dólar',
    menuParent: 'feed_semanal',
    pageTitle: 'Feed Semanal - Ibov/Bitcoin/Dólar',
    component: <FeedCurrencyForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'Padrão' },
      { slug: 'gwm-azul', name: 'Azul' },
    ],
  },
  focus: {
    slug: 'focus',
    menuName: 'Focus',
    menuParent: null,
    pageTitle: 'Focus',
    component: <FocusForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
      { slug: 'gwm', name: 'GWM' },
      { slug: 'the-hill', name: 'The Hill' },
    ],
  },
  jornal: {
    slug: 'jornal',
    menuName: 'Jornal',
    menuParent: null,
    pageTitle: 'Jornal',
    component: <JornalForm />,
    models: [
      { slug: 'page1', name: 'Página 1' },
      { slug: 'page2', name: 'Página 2' },
      { slug: 'page3', name: 'Página 3' },
      { slug: 'page4', name: 'Página 4' },
      { slug: 'page5', name: 'Página 5' },
      { slug: 'page6', name: 'Página 6' },
      { slug: 'page7', name: 'Página 7' },
      { slug: 'page8', name: 'Página 8' },
    ],
  },
  happeningNewsBrazil: {
    slug: 'noticia-acontecendo-brasil',
    menuName: 'Acontecendo Brasil',
    menuParent: 'news',
    pageTitle: 'Notícia acontecendo agora Brasil',
    component: <HappeningNewsBrazilForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  happeningNewsWorld: {
    slug: 'noticia-acontecendo-mundo',
    menuName: 'Acontecendo mundo',
    menuParent: 'news',
    pageTitle: 'Notícia acontecendo agora Mundo',
    component: <HappeningNewsWorldForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  metalCommodities: {
    slug: 'commodities-metalicas',
    menuName: 'Commodities Metálicas',
    menuParent: null,
    pageTitle: 'Commodities Metálicas',
    component: <MetalCommoditiesForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed', name: 'Feed' },
    ],
  },
  moedasMundo: {
    slug: 'moedas-mundo',
    menuName: 'Moedas Mundo',
    menuParent: null,
    pageTitle: 'Moedas Mundo',
    component: <MoedasMundoForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed', name: 'Feed' },
    ],
  },
  breakingNews: {
    slug: 'noticia-urgente',
    menuName: 'Urgente',
    menuParent: 'news',
    pageTitle: 'Notícia urgente',
    component: <BreakingNewsForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  minute: {
    slug: 'minutogwm',
    menuName: 'Minuto GWM',
    menuParent: null,
    pageTitle: 'Minuto GWM',
    component: <MinuteForm />,
    models: [
      { slug: 'gwm', name: 'GWM' },
    ],
  },
  formulaDrawing: {
    slug: 'formula-sorteio',
    menuName: 'Sorteio Fórmula',
    menuParent: null,
    pageTitle: 'Sorteio Fórmula',
    component: <FormulaDrawingForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  temporadaBalancos: {
    slug: 'temporada-balancos',
    menuName: 'Temporada de Balanços',
    menuParent: null,
    pageTitle: 'Temporada de Balanços',
    component: <TemporadaBalancosForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  superQuarta: {
    slug: 'super-quarta',
    menuName: 'Super Quarta',
    menuParent: 'fed_copom',
    pageTitle: 'FED/COPOM - Super Quarta',
    component: <SuperQuartaForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  taxaJurosEUA: {
    slug: 'taxa-juros-eua',
    menuName: 'Taxa-Alvo Juros EUA',
    menuParent: null,
    pageTitle: 'Taxa-Alvo Juros EUA',
    component: <TaxaJurosEUAForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  taxaSelic: {
    slug: 'taxa-selic',
    menuName: 'Taxa Selic',
    menuParent: 'fed_copom',
    pageTitle: 'FED/COPOM - Taxa Selic',
    component: <TaxaSelicForm />,
    models: [
      { slug: 'stories', name: 'Stories' },
      { slug: 'feed', name: 'Feed' },
    ],
  },
  noticiasCrypto: {
    slug: 'noticias-crypto',
    menuName: 'Notícias Crypto',
    menuParent: null,
    pageTitle: 'Notícias Crypto',
    component: <NoticiasCryptoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  twitter: {
    slug: 'twitter',
    menuName: 'Twitter',
    menuParent: null,
    pageTitle: 'Twitter',
    component: <TwitterForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  imagemPersonalizada: {
    slug: 'imagem-personalizada',
    menuName: 'Imagem Personalizada',
    menuParent: null,
    pageTitle: 'Imagem Personalizada',
    component: <ImagemPersonalizadaForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  fundRanking: {
    slug: 'ranking-fundos',
    menuName: 'Ranking de Fundos',
    menuParent: null,
    pageTitle: 'Ranking de Fundos',
    component: <FundRankingForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  FundosImobiliarios: {
    slug: 'fundos-imobiliarios',
    menuName: 'Fundos Imobiliários',
    menuParent: null,
    pageTitle: 'Fundos Imobiliários',
    component: <FundosImobiliariosForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  tesouroDireto: {
    slug: 'tesouro-direto',
    menuName: 'Tesouro Direto',
    menuParent: null,
    pageTitle: 'Tesouro Direto',
    component: <TesouroDiretoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  tesouroDiretoFeed: {
    slug: 'tesouro-direto-feed',
    menuName: 'Tesouro Direto Feed',
    menuParent: null,
    pageTitle: 'Tesouro Direto Feed',
    component: <TesouroDiretoFeedForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  fluxoInvestimentoB3: {
    slug: 'fluxo-investimento-b3',
    menuName: 'Fluxo de investimento B3',
    menuParent: null,
    pageTitle: 'Fluxo de investimento B3',
    component: <FluxoInvestimentoB3Form />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  aberturaMercadoMontebravo: {
    slug: 'abertura-mercado-montebravo',
    menuName: 'Abertura de mercado',
    menuParent: 'montebravo',
    pageTitle: 'Montebravo - Abertura de mercado',
    component: <AberturaMercadoMontebravoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
  fechamentoMercadoMontebravo: {
    slug: 'fechamento-mercado-montebravo',
    menuName: 'Fechamento de mercado',
    menuParent: 'montebravo',
    pageTitle: 'Montebravo - Fechamento de mercado',
    component: <FechamentoMercadoMontebravoForm />,
    models: [
      { slug: 'Default', name: 'Assessores' },
    ],
  },
}
