import React from 'react';
import { Form, Input, Select } from 'antd';

import './PetroleoForm.scss';

const { Option } = Select;

const PetroleoForm = () => {

  return <div className="PetroleoForm">

    <div className="line">
      <Form.Item
        name="petroleo_date"
        label="Data referência"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="petroleo_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h3>Petróleo Brent</h3>
    <div className="line">
      <Form.Item
        name="petroleo_brent_valor"
        label="Valor US$"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="petroleo_brent_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="petroleo_brent_variacao_status" label="Variação status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>
    <div className="line">
      <Form.Item
        name="petroleo_brent_low"
        label="Low"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="petroleo_brent_high"
        label="High"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="petroleo_brent_termometro" label="Termômetro" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="extreme_fear">Extreme Fear</Option>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
          <Option value="extreme_greed">Extreme Greed</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Petróleo WTI</h3>
    <div className="line">
      <Form.Item
        name="petroleo_wti_valor"
        label="Valor US$"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="petroleo_wti_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="petroleo_wti_variacao_status" label="Variação status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>
    <div className="line">
      <Form.Item
        name="petroleo_wti_low"
        label="Low"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="petroleo_wti_high"
        label="High"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="petroleo_wti_termometro" label="Termômetro" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="extreme_fear">Extreme Fear</Option>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
          <Option value="extreme_greed">Extreme Greed</Option>
        </Select>
      </Form.Item>
    </div>

  </div>
};
export default PetroleoForm;