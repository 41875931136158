import React, { useState } from 'react';
import { Form, Input, Radio, Select } from 'antd';

import './TaxaSelicForm.scss';

const { TextArea } = Input;
const { Option } = Select;

const options = [
  { label: 'Valor único', value: 'single' },
  { label: 'Faixa', value: 'range' },
];

const TaxaSelicForm = () => {
  const [isCopomRange, setIsCopomRange] = useState(false);

  const onChangeCopom = ({ target: { value } }: RadioChangeEvent) => {
    setIsCopomRange(value === 'range');
  };

  return <div className="TaxaSelicForm">
    <Form.Item
      name="indices_americanos_model"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
      style={{ minWidth: 260 }}
      label="Modelo"
    >
      <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Option value="stories">Stories</Option>
        <Option value="feed">Feed</Option>
      </Select>
    </Form.Item>

    <Form.Item
      name="taxa_selic_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="taxa_selic_texto"
      label="Texto principal"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={4} />
    </Form.Item>

    <h3>COPOM</h3>

    <Radio.Group options={options} onChange={onChangeCopom} optionType="button" defaultValue={'single'} />
    <br />
    <br />

    <Form.Item
      name="taxa_selic_copom_valor1"
      label={isCopomRange ? 'De' : 'Valor'}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    { isCopomRange && (
      <Form.Item
        name="taxa_selic_copom_valor2"
        label="Até"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    )}

    <Form.Item
      name="taxa_selic_copom_texto"
      label="Texto"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={3} />
    </Form.Item>
    <Form.Item
      name="taxa_selic_copom_fonte_texto"
      label="Fonte"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

  </div>
};
export default TaxaSelicForm;