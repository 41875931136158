import React from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { dummyRequest, normFile } from '../../../utils/upload';

import './NoticiasCryptoForm.scss';

const { TextArea } = Input;

const NoticiasCryptoForm = () => {

  return <div className="NoticiasCryptoForm">
    <Form.Item
      name="noticias_crypto_titulo"
      label="Título"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={3} />
    </Form.Item>


    <h3>Notícia</h3>
    <Form.List name="noticias_crypto_items">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (

            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'noticia']}
                style={{ minWidth: 120, flex: 1 }}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <TextArea placeholder="Notícia" rows={2} />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>



    <Form.Item
      name="noticias_crypto_fonte"
      label="Fonte"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="noticias_crypto_imagem"
      label="Imagem"
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload
        name="noticias_crypto_imagem"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>

  </div>
};
export default NoticiasCryptoForm;