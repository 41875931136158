import React, { useState, useEffect } from 'react';
import { Button, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import './PrivatePageWrapper.scss';
import { menuList, getMenuItem } from '../../components/layout/menuItems';
import logo from '../../assets/images/logo.png';
import logoCollapsed from '../../assets/images/logo-square.png';
import HeaderMenu from './components/headerMenu/HeaderMenu';

function PrivatePageWrapper({ children, slug, pageTitle, menuParent }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Verifica se a preferência de cores escuras está ativada no sistema
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // Atualiza o estado com base na preferência de cores do sistema
    const handleDarkModeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    // Adiciona um ouvinte de mudança para atualizar o estado quando a preferência de cores mudar
    darkModeMediaQuery.addListener(handleDarkModeChange);

    // Verifica e define o estado inicial
    setIsDarkMode(darkModeMediaQuery.matches);

    // Remove o ouvinte quando o componente é desmontado
    return () => {
      darkModeMediaQuery.removeListener(handleDarkModeChange);
    };
  }, []);

  const onClick = (e) => {
    const { key } = e;
    if (key === slug) return;
    navigate(`/${key}`);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`PrivatePageWrapper ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>
      <div className={`menu ${ collapsed ? '' : 'full'}`}>
        <Menu
          theme="dark"
          onClick={onClick}
          className={`no-select ${ collapsed ? 'hidden-mobile' : ''}`}
          defaultSelectedKeys={[slug]}
          defaultOpenKeys={[menuParent]}
          mode="inline"
          inlineCollapsed={collapsed}
          items={[getMenuItem(collapsed ? <img className="logo" alt="" src={logoCollapsed} /> : <img className="logo" alt="" src={logo} />, 'grp', null, menuList,'group')]}
        />
      </div>

      <div className="page-container">
        <div className="page-header">
          <div className="page-header-left">
            <Button type="text" className="btn-menu-toggle" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <div className="page-title">{pageTitle}</div>
          </div>
          <div className="page-header-right"><HeaderMenu /></div>
        </div>
        <div className="page-content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default PrivatePageWrapper;
