import React from 'react';
import { Form, Input, Select } from 'antd';

import './MoedasMundoForm.scss';

const { Option } = Select;

const MoedasMundoForm = () => {

  return <div className="MoedasMundoForm">

    <div className="line-full">
      <Form.Item name="moedas_mundo_modelo" label="Modelo" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="feed">Feed</Option>
          <Option value="stories">Stories</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="moedas_mundo_date"
        label="Data referência"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Dólar</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_dolar_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_dolar_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_dolar_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Euro</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_euro_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_euro_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_euro_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Yuan</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_yuan_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_yuan_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_yuan_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Libra</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_libra_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_libra_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_libra_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Franco Suíço</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_franco_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_franco_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_franco_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <h3>Dólar Canadense</h3>
    <div className="line">
      <Form.Item
        name="moedas_mundo_canadense_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moedas_mundo_canadense_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="moedas_mundo_canadense_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>




  </div>
};
export default MoedasMundoForm;