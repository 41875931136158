import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './AmericanIndexesForm.scss';

const { Option } = Select;

const AmericanIndexesForm = () => {

  return <div className='AmericanIndexesForm'>
    <div className="line-full">
      <Form.Item
        name="indices_americanos_model"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
        style={{ minWidth: 260 }}
        label="Modelo"
      >
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="stories">Stories</Option>
          <Option value="feed">Feed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="indices_americanos_date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indices_americanos_hora"
        label="Horário"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h3>Itens com metade da linha</h3>
    <Form.List name="indices_americanos_metade">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (

            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'nome']}
                style={{ minWidth: 120, flex: 1 }}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'valor']}
                style={{ minWidth: 120}}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Valor" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                style={{ minWidth: 120}}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Variação" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'status']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120 }}
              >
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Status">
                  <Option value="aumento">Aumento</Option>
                  <Option value="diminuicao">Diminuição</Option>
                </Select>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>


    <h3>Pedidos de auxílio-desemprego</h3>
    <div className="line-full">
      <Form.Item
        name="indices_americanos_auxilio_desemprego_atual"
        label="Atual"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indices_americanos_auxilio_desemprego_anterior"
        label="Anterior"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indices_americanos_auxilio_desemprego_divulgacao"
        label="Divulgação"
        style={{ flex: 1 }}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h3>PCE (Anual)</h3>
    <div className="line-full">
      <Form.Item
        name="indices_americanos_pce_atual"
        label="Atual"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indices_americanos_pce_anterior"
        label="Anterior"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indices_americanos_pce_divulgacao"
        label="Divulgação"
        style={{ flex: 1 }}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>



  </div>
};
export default AmericanIndexesForm;