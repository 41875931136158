import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './JurosFuturosForm.scss';

const { Option } = Select;

const JurosFuturosForm = () => {
  return <div className="JurosFuturosForm">


    <Form.Item
      name="juros_futuros_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="juros_futuros_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="juros_futuros_model"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
      style={{ minWidth: 260 }}
      label="Modelo"
    >
      <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Option value="stories">Story</Option>
        <Option value="feed">Feed</Option>
      </Select>
    </Form.Item>

    <h3>Itens</h3>
    <Form.List name="juros_futuros">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'nome']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'vencimento']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Vencimento" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'status']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120 }}
              >
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Status">
                  <Option value="aumento">Aumento</Option>
                  <Option value="diminuicao">Diminuição</Option>
                </Select>
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'taxa']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Taxa" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>


  </div>
};
export default JurosFuturosForm;