import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './MarketClosureForm.scss';

const { Option } = Select;

const MarketClosureForm = () => {

  return <div className='MarketClosureForm'>
    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="fechamento_mercado_fundo_assessores" label="Cor fundo assessores" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="azul">Azul</Option>
          <Option value="preto">Preto</Option>
        </Select>
      </Form.Item>
    </div>

    <Form.Item
      name="fechamento_mercado_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <h3>Altas</h3>
    <Form.List name="fechamento_mercado_alta">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line" key={key} align="baseline">

              <Form.Item
                {...restField}
                name={[name, 'nome']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Sigla"
              >
                <Input placeholder="Sigla" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'nome_completo']}
                label="Nome"
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Variação"
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'total']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Valor"
              >
                <Input placeholder="Valor" />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>


    <h3>Baixas</h3>
    <Form.List name="fechamento_mercado_baixa">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line" key={key} align="baseline">

              <Form.Item
                {...restField}
                name={[name, 'nome']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Sigla"
              >
                <Input placeholder="Sigla" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'nome_completo']}
                label="Nome"
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Variação"
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'total']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Valor"
              >
                <Input placeholder="Valor" />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>



    <h3>Ibovespa/Bitcoin/Dólar</h3>
    <div className="line-full">
      <Form.Item
        name="fechamento_mercado_ibovespa_variacao"
        label="Ibovespa variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fechamento_mercado_ibovespa_total"
        label="Ibovespa total"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="fechamento_mercado_ibovespa_status" label="Ibovespa status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line-full">
      <Form.Item
        name="fechamento_mercado_bitcoin_real_variacao"
        label="Bitcoin-real variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fechamento_mercado_bitcoin_real_total"
        label="Bitcoin-real total"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="fechamento_mercado_bitcoin_real_status" label="Bitcoin-real status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line-full">
      <Form.Item
        name="fechamento_mercado_bitcoin_dolar_variacao"
        label="Bitcoin-dólar variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fechamento_mercado_bitcoin_dolar_total"
        label="Bitcoin-dólar total"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="fechamento_mercado_bitcoin_dolar_status" label="Bitcoin-dólar status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>




    <div className="line-full">
      <Form.Item
        name="fechamento_mercado_dolar_variacao"
        label="Dólar variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="fechamento_mercado_dolar_total"
        label="Dólar total"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="fechamento_mercado_dolar_status" label="Dólar status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>

  </div>
};
export default MarketClosureForm;